export const renderPagination = (searchResults, pages, handlePagination, handlePaginationControlButtons) => {
  const { max, current } = pages;
  const paginationContainer = document.querySelector('.joblist-pagination');
  const paginationList = document.querySelector('.joblist-pagination__pages');

  if (!paginationList || !paginationContainer) return;

  if ((max > 1 && searchResults.length === 25) || (current !== 1 && searchResults.length < 25)) {
      paginationContainer.style.display = 'flex';
  } else {
    paginationContainer.style.display = 'none';
    return;
  }

  paginationList.innerHTML = '';

  for (let i = 1; i <= max; i++) {
    const listItem = document.createElement('li');
    listItem.classList.add('joblist-pagination__page-item');

    const pageButton = document.createElement('button');
    pageButton.classList.add('joblist-pagination__page-item-button');
    pageButton.textContent = i;
    pageButton.dataset.page = i;

    if (i === current) {
      listItem.classList.add('joblist-pagination__page-item--active');
    }

    pageButton.addEventListener('click', () => handlePagination({ target: pageButton }));

    listItem.appendChild(pageButton);
    paginationList.appendChild(listItem);
  }

  const prevButton = document.querySelector('.joblist-pagination__control-button--prev');
  const nextButton = document.querySelector('.joblist-pagination__control-button--next');

  if (prevButton) {
    prevButton.disabled = current === 1;
    prevButton.onclick = () => {
      if (current > 1) handlePaginationControlButtons(current - 1);
    };
  }

  if (nextButton) {
    nextButton.disabled = current === max;
    nextButton.onclick = () => {
      if (current < max) handlePaginationControlButtons(current + 1);
    };
  }

  if (max <= 1) {
    paginationContainer.style.display = 'none';
  }
};