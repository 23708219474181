
(function(){
    (function() {
        var initState = {
            initObj: null,
            initialize: function(sourceProductId, sourceProductName, langIsocode, selectErrorMsg) {
                if(!this.initObj) {
                    this.initObj = init(langIsocode, selectErrorMsg);
                    this.initObj.initAcsView();
                }
                this.initObj.setSourceProductId(sourceProductId, sourceProductName);
            }
        };

        function initializeHelper() {
            var sourceProductId = $('.acs-view').attr('data-acs-id-product-code');
            var sourceProductName = $('.acs-view').attr('data-acs-id-product-name');
            var langIsocode = $('.acs-view').attr('data-acs-lang-isocode');
            var selectErrorMsg = $('.acs-view').attr('data-acs-id-select-error');
            initState.initialize(sourceProductId, sourceProductName, langIsocode, selectErrorMsg);
        }

        $('.acs-action-open').on('acs-show', function() {
            initializeHelper();
        });

        if($('.acs-view').length > 0) {
            initializeHelper();
        }
    })();

    function init(langIsocode, selectErrorMsg) {

        Math.log10 = Math.log10 || function(x) {
            return Math.log(x) * Math.LOG10E;
        };

        function stateChanged() {
            ui.draw(state);
        }
        function stateChangedNoData() {
            ui.drawNoDataChange(state);
        }
        function stateChangedOnlyProductData() {
            ui.drawOnlyProductDataChange(state);
        }
        function stateChangedSelectBox(selectionDiscreteValue) {
            if(!selectionDiscreteValue) return;
            ui.updateSelectBox(selectionDiscreteValue);
        }
        function countDecimals(value) {
            // Example: 19.987 ==> 3
            if(Math.floor(value) === value) return 0;
            return value.toString().split('.')[1].length || 0;
        }
        function decodeHtml(textWithHtlmEntities) {
            var textElem = window.document.createElement('textarea');
            textElem.innerHTML = textWithHtlmEntities;
            // handle case of empty input
            return textElem.childNodes.length === 0 ? "" : textElem.childNodes[0].nodeValue;
        }

        function Product(id, name) {
            this.id = id;
            this.name = name;
            this.configurable = false;
            this.desc = '';
            this.productUrl = null;
            this.imageUrl = null;
            this.imageAltText = '';
        }

        function Products() {
            this.products = [];
        }

        Products.prototype.setProducts = function(products) {
            if(!products || !products.length) return false;
            if(products.length !== this.products.length) {
                this.products = products;
                return true;
            }
            for(var i = 0; i < products.length; i++) {
                if(this.products[i] !== products[i]) {
                    this.products = products;
                    return true;
                }
            }
            return false;
        };

        Products.prototype.count = function() {
            return this.products.length;
        };

        function FacetValue(id, displayName, count) {
            this.id = id;
            this.displayName = displayName;
            count ? this.count = count : this.count = 0;
        }
        FacetValue.prototype.equals = function(facetValue) {
            if(!facetValue) {
                return false;
            }
            return this.id === facetValue.id;
        };
        function createNoSelectionFacetValue(displayName) {
            if(!displayName) displayName = 'completeRange';
            return new FacetValue('completeRange', displayName, 1);
        }
        var noSelectionFacetValue = createNoSelectionFacetValue();

        function DiscreteValue(facetValue, allFacetValues, defaultFacetValue) {

            if(!allFacetValues || !defaultFacetValue) {
                throw new Error('Invalid DiscreteValue constructor arguments');
            }
            this.defaultFacetValue = defaultFacetValue;

            this.facetValue = this.defaultFacetValue;
            if(facetValue) this.facetValue = facetValue;

            this.allFacetValues = [];
            this.enabledFacetValues = [];
            if(allFacetValues && allFacetValues.length) {
                this.allFacetValues = allFacetValues.slice();
                this.enabledFacetValues = allFacetValues.slice();
            }
        }

        DiscreteValue.prototype.getFacetValue = function(facetPropName, facetPropValue, onlyEnabled) {

            var facetValues = onlyEnabled ? this.enabledFacetValues : this.allFacetValues;
            if(!facetValues || facetValues.length === 0) return null;

            for(var i = 0; i < facetValues.length; ++i) {
                if(facetValues[i][facetPropName] === facetPropValue) {
                    return facetValues[i];
                }
            }
            return null;
        };

        DiscreteValue.prototype.validFacetValue = function(facetValue) {
            if(this.allFacetValues === null) return true;
            if(!facetValue) return false;
            if(facetValue.equals(noSelectionFacetValue)) return true;
            for(var i = 0; i < this.allFacetValues.length; i++) {
                if(facetValue.equals(this.allFacetValues[i])) {
                    return true;
                }
            }
            return false;
        };

        DiscreteValue.prototype.setFacetValueByDisplayName= function(value) {

            var facetValue = this.getFacetValue('displayName', value, false);
            if(facetValue === this.facetValue) return false;
            this.facetValue = facetValue;

            return true;
        };

        DiscreteValue.prototype.reset = function() {
            if(!this.canReset()) return false;
            this.facetValue = this.defaultFacetValue;
            return true;
        };
        DiscreteValue.prototype.canReset = function() {
            return !this.facetValue.equals(this.defaultFacetValue);
        };

        DiscreteValue.prototype.setEnabledFacetValues = function(facetValues) {
            this.enabledFacetValues = [];
            for(var i = 0; i < facetValues.length; i++) {
                if(this.validFacetValue(facetValues[i])) {
                    this.enabledFacetValues.push(facetValues[i]);
                }
            }
            return true;
        };
        DiscreteValue.prototype.equals = function(discreteValue) {
            if(!discreteValue) return false;
            this.facetValue.id === discreteValue.facetValue.id;
        };
        DiscreteValue.prototype.hasSelection = function() {
            return !this.facetValue.equals(noSelectionFacetValue);
        };
        DiscreteValue.prototype.hasEnabledValue = function() {
            // Larger than 1 because of the completeRange option
            return this.enabledFacetValues.length > 1;
        };
        DiscreteValue.prototype.setNoSelectionFacetText = function(textIfNoSelection, textIfSelection) {

            for(var i = 0; i < this.allFacetValues.length; i++) {
                if(this.allFacetValues[i].equals(noSelectionFacetValue)) {

                    this.hasSelection()
                        ? this.allFacetValues[i].displayName = textIfSelection
                        : this.allFacetValues[i].displayName = textIfNoSelection;

                    break;
                }
            }
        };
        function SelectionDiscreteValue(id, discreteValue, name) {
            this.id = id;
            this.discreteValue = discreteValue;
            this.type = 'discrete';
            this.name = name;
        }

        function Selections() {
            this.selections = [];
            this.dirty = true;
        }

        Selections.prototype.setSelections = function(selections) {

            var _this = this;
            if(this.selections.length !== selections.length) {
                this.selections = selections;
                this.dirty = true;
                return;
            }
            function hasSelection(id) {
                for(var i = 0; i < _this.selections.length; i++) {
                    if(_this.selections[i].id === id) return true;
                }
                return false;
            }

            for(var i = 0; i < selections.length; i++) {
                if(!hasSelection(selections[i].id)) {
                    this.selections = selections;
                    this.dirty = true;
                    return;
                }
            }

            if(selections.length === 0 || selections[0].type !== 'range' || this.isDirty()) {
                this.selections = selections;
            }

            this.dirty = false;
        };
        Selections.prototype.length = function() {
            return this.selections.length;
        };
        Selections.prototype.get = function(i) {
            if(i >= this.selections.length) throw new Error('Out of bounds, ' + i);
            return this.selections[i];
        };
        Selections.prototype.isDirty = function() {
            return this.dirty;
        };
        Selections.prototype.setDirtyFlag = function(dirty) {
            this.dirty = dirty;
        };
        Selections.prototype.hasSelection = function(id) {
            for(var i = 0; i < this.selections.length; i++) {
                if(this.selections[i].id === id) return true;
            }
            return false;
        };

        function Range(min, max, scaleMarks) {

            min = Number(min);
            if(isNaN(min)) min = 0;

            max = Number(max);
            if(isNaN(max)) max = 100;

            this.min = min;
            this.max = max;

            if(min > max) {
                this.min = max;
                this.max = min;
            }
            this.val1 = this.min;
            this.val2 = this.max;

            this.currentMin = this.min;
            this.currentMax = this.max;

            this.scaleMarks = null;
            if(!scaleMarks || !scaleMarks.length) return;

            this.scaleMarks = scaleMarks.slice();
            for(var i = 0; i < scaleMarks.length; i++) {
                this.scaleMarks[i] = Number(scaleMarks[i]);

                if(isNaN(this.scaleMarks[i]) || this.scaleMarks[i] <= this.min || this.scaleMarks[i] >= this.max) {
                    this.scaleMarks = null;
                    return;
                }
                if(i > 0 && this.scaleMarks[i] <= this.scaleMarks[i - 1]) {
                    this.scaleMarks = null;
                    return;
                }
            }

            this.scaleMarks.unshift(this.min);
            this.scaleMarks.push(this.max);
        }
        Range.prototype.getVal1 = function() {
            return this.val1 > this.currentMin
                ? this.val1
                : this.currentMin;
        };
        Range.prototype.getVal2 = function() {
            return this.val2 < this.currentMax
                ? this.val2
                : this.currentMax;
        };

        Range.prototype.setValuesConstrained = function(val1, val2) {

            val1 = Number(val1);
            val2 = Number(val2);

            if(this.valuesEquals(val1, val2)) return false;

            if(isNaN(val1)) val1 = this.min;
            if(isNaN(val2)) val2 = this.max;

            if(val1 > val2) {
                var temp = val2;
                val2 = val1;
                val1 = temp;
            }
            var _this = this;

            function constrain(val) {
                if(val < _this.min) return _this.min;
                if(val > _this.max) return _this.max;
                return val;
            }

            this.val1 = constrain(val1);
            this.val2 = constrain(val2);

            return true;
        };

        Range.prototype.setCurrentMinMax = function(min, max) {

            if(min > max) return false;
            if(this.currentMin === min && this.currentMax === max) return false;

            this.currentMin = min;
            this.currentMax = max;
            return true;
        };
        Range.prototype.valuesEquals = function(val1, val2) {
            return this.val1 === val1 && this.val2 === val2;
        };
        Range.prototype.reset = function() {
            if(!this.canReset()) return false;
            this.val1 = this.min;
            this.val2 = this.max;
            return true;
        };
        Range.prototype.canReset = function() {
            return this.val1 !== this.min ||
                this.val2 !== this.max;
        };
        function SelectionRange(id, name, range) {
            this.id = id;
            this.name = name;
            this.range = range;
            this.type = 'range';
        }

        function Mode(id) {
            this.id = Mode.modeStd;
            if(id === Mode.modeAdv || id === Mode.modeMan) {
                this.id = id;
            }
        }
        Mode.modeStd = 'acs-id-mode-std';
        Mode.modeAdv = 'acs-id-mode-adv';
        Mode.modeMan = 'acs-id-mode-man';

        Mode.prototype.validId = function(modeId) {
            return modeId === Mode.modeStd || modeId === Mode.modeAdv || modeId === Mode.modeMan;
        };
        Mode.prototype.set = function(modeId) {
            if(this.id === modeId) return false;
            if(!this.validId(modeId)) return false;
            this.id = modeId;
            return true;
        };
        Mode.prototype.isModeManual = function() {
            return this.id === Mode.modeMan;
        };

        function ComboData() {
            this.mounted = true;
            this.connector = false;
            this.hasMountingOption = false;
            this.hasPipeConnectorOption = false;
        }
        ComboData.mapOption = function(option) {
            // Example: 'true-false' => {mounted: true, connector: false}
            var comboOptionArr = option.split('-');
            if(comboOptionArr.length != 2) throw new Error('Invalid option: ' + option);
            return {
                mounted: comboOptionArr[0] === 'true',
                connector: comboOptionArr[1] === 'true'
            };
        };
        ComboData.mapAvailableOptions = function(optionKeys) {
            // Example: ['true-true', 'true-false'] => {hasMountingOption: false, hasPipeConnectorOption: true}
            var options = optionKeys.map(function(optionKey) {
                return ComboData.mapOption(optionKey);
            });

            var result = {hasMountingOption: false, hasPipeConnectorOption: false};
            for(var i = 1; i < options.length; i++) {
                if(options[i - 1].mounted !== options[i].mounted) {
                    result.hasMountingOption = true;
                    break;
                }
            }
            for(var i = 1; i < options.length; i++) {
                if(options[i - 1].connector !== options[i].connector) {
                    result.hasPipeConnectorOption = true;
                    break;
                }
            }
            return result;
        };
        ComboData.create = function(optionMap) {
            if(!optionMap) {
                throw new Error('Invalid option map: ' + optionMap);
            }
            var optionKeys = Object.keys(optionMap);
            if(optionKeys.length === 0) {
                throw new Error('Empty option map: ' + optionMap);
            }

            var initial = ComboData.mapOption(optionKeys[0]);
            var comboData = new ComboData();
            comboData.mounted = initial.mounted;
            comboData.connector = initial.connector;

            var availableOptions = ComboData.mapAvailableOptions(optionKeys);
            comboData.hasMountingOption = availableOptions.hasMountingOption;
            comboData.hasPipeConnectorOption = availableOptions.hasPipeConnectorOption;
            return comboData;
        };
        ComboData.prototype.getKey = function() {
            return (this.mounted ? 'true' : 'false')
                    + '-'
                    + (this.connector ? 'true' : 'false');
        };

        var requestUriPrefix = window && window.countryCode ? window.countryCode : '';
        var consts = {
            urlSearch: requestUriPrefix + '/shop/' + langIsocode + '/actuator-selection/search',
            urlCombo: requestUriPrefix + '/shop/' + langIsocode + '/actuator-selection/combo',
        };

        var state = {

            dataModelCombo: {

                comboData: null,
                rawComboData: null,
                setOptionsFromOptionData: function(optionMap) {
                    this.comboData = ComboData.create(optionMap);
                },
                reset: function() {
                    this.comboData = null;
                },
                setRawComboData: function(rawComboData) {
                    this.rawComboData = rawComboData;
                },
                navigateToCombo: function() {
                    var key = this.comboData.getKey();
                    if(!this.rawComboData.comboMountingPipeOptionsMap[key]) return;
                    var productCode = this.rawComboData.comboMountingPipeOptionsMap[key];
                    var resultProductArray = this.rawComboData.products.filter(function(product){
                       return product.code === productCode;
                    });
                    if(resultProductArray.length == 1) {
                        window.window.location = resultProductArray[0].fullUrl;
                    }
                },
            },

            dataModel: {
                sourceProductId: null, // Id of product for which to find a fitting related product.
                sourceProductName: null, // Name of product for which to find a fitting related product.
                products: new Products(), // All related products.
                selectionsDiscreteValue: new Selections(), // Contains an array of discrete selections (e.g. select boxes).
                selectionsRange: new Selections(), // Contains an array of range selections (e.g. sliders).
                mode: new Mode(Mode.modeStd), // State of selections (active/visible vs. inactive/hidden).
                maxProductCount: -1, // The number of available actuators for the current product without any selection applied.
                selectedActuatorId: null, // The id of the selected actuator (if any).
                actuatorSearchRunning: false, // Ajax request ongoing (set to true with small delay).
                comboSearchRunning: false, // Ajax request ongoing (set to true with small delay)..
                actuatorSearchRunningLight: false, // Ajax request ongoing (set to true without delay).
                comboSearchRunningLight: false, // Ajax request ongoing (set to true without delay).
                defaultOptionText: 'CompleteRange', // Text to use for the default select option.
                defaultOptionClearText: 'Clear', // Text to use for the default select option, if an option is selected.
                actuatorSearchRunningTimeoutFn: null, // Helper function to set variable to true with delay.
                comboSearchRunningTimeoutFn: null, // Helper function to set variable to true with delay.
                setSourceProductId: function(sourceProductId, sourceProductName) {
                    if(this.sourceProductId === sourceProductId) return;
                    this.sourceProductId = sourceProductId;
                    this.sourceProductName = sourceProductName;
                    this.maxProductCount = -1;
                    stateChanged();
                    this.action_getActuators(true);
                },

                setProducts: function(productArr, callStateChanged) {
                    if(this.products.setProducts(productArr)) {
                        if(callStateChanged) stateChanged();
                    }
                },

                discreteValue: function(id) {
                    var sdValue = this.selectionDiscreteValue(id);
                    if(!sdValue) return null;
                    return sdValue.discreteValue;
                },
                selectionDiscreteValue: function(id) {
                    for(var i = 0; i < this.selectionsDiscreteValue.length(); ++i) {
                        if(this.selectionsDiscreteValue.get(i).id === id) {
                            return this.selectionsDiscreteValue.get(i);
                        }
                    }
                    return null;
                },
                setDiscreteValue: function(id, value) {
                    var discreteValue = this.discreteValue(id);
                    if(!discreteValue) return;

                    if(discreteValue.setFacetValueByDisplayName(value)) {

                        // Set the 'Clear' option back to 'Choose value' already at this point.
                        // This will prevent a flicker in the UI later on.
                        if(discreteValue.facetValue.equals(noSelectionFacetValue)) {
                            discreteValue.facetValue.displayName = this.defaultOptionText;
                            stateChangedSelectBox(this.selectionDiscreteValue(id));
                        }

                        this.action_getActuators();
                    }
                },

                range: function(id) {
                    for(var i = 0; i < this.selectionsRange.length(); i++) {
                        if(this.selectionsRange.get(i).id == id) {
                            return this.selectionsRange.get(i).range;
                        }
                    }
                    return null;
                },

                setRangeConstrained: function(id, val1, val2) {

                    var range = this.range(id);
                    if(!range) return;

                    if(range.setValuesConstrained(val1, val2)) {
                        this.action_getActuators();
                        // stateChanged();
                    }
                },

                setMode: function(modeId) {
                    if(this.mode.set(modeId)) {
                        this.maxProductCount = -1; // reinit the max count of products.
                        this.action_getActuators(true);
                    }
                },
                setDefaultOptionText: function(text) {
                    this.defaultOptionText = text;
                },
                setDefaultOptionClearText: function(text) {
                    this.defaultOptionClearText = text;
                },
                resetSelections: function() {

                    for(var i = 0; i < this.selectionsRange.length(); i++) {
                        this.selectionsRange.get(i).range.reset();
                    }
                    this.action_getActuators(true);
                },

                canResetSelections: function() {

                    if (this.selectionsDiscreteValue.length() === 0 && this.selectionsRange.length() === 0) {
                        return true;
                    }
                    for (var i = 0; i < this.selectionsDiscreteValue.length(); i++) {
                        if (this.selectionsDiscreteValue.get(i).discreteValue.canReset()) {
                            return true;
                        }
                    }
                    for (var i = 0; i < this.selectionsRange.length(); i++) {
                        if (this.selectionsRange.get(i).range.canReset()) {
                            return true;
                        }
                    }
                    return false;
                },

                getSelectionDiscreteValueFromFacetData: function(facet) {

                    var allFacetValues = [];
                    var enabledFacetValues = [];
                    var selectedFacetValue = null;

                    for(var i = 0; i < facet.values.length; i++) {

                        var facetValueIn = facet.values[i];
                        var facetValue = new FacetValue(
                            facetValueIn.code,
                            facetValueIn.name,
                            facetValueIn.count);
                        allFacetValues.push(facetValue);

                        if(facetValueIn.selected) {
                            selectedFacetValue = facetValue;
                        }
                        if(facetValueIn.count > 0) {
                            enabledFacetValues.push(facetValue);
                        }
                    }


                    var facetValueNoSelection = createNoSelectionFacetValue(this.defaultOptionText);
                    allFacetValues.push(facetValueNoSelection);
                    enabledFacetValues.push(facetValueNoSelection);
                    if(!selectedFacetValue) {
                        selectedFacetValue = facetValueNoSelection;
                    }

                    var discreteValue = new DiscreteValue(selectedFacetValue, allFacetValues, facetValueNoSelection);
                    var selectionDiscreteValue = new SelectionDiscreteValue(
                        facet.code, discreteValue, facet.name);
                    selectionDiscreteValue.discreteValue.setEnabledFacetValues(enabledFacetValues);

                    discreteValue.setNoSelectionFacetText(
                        state.dataModel.defaultOptionText, state.dataModel.defaultOptionClearText);

                    return selectionDiscreteValue;
                },
                setFromFacetDataList: function(facets) {
                    var selections = [];
                    for(var i = 0; i < facets.length; i++) {
                        var selection = this.getSelectionDiscreteValueFromFacetData(facets[i]);
                        if(selection) selections.push(selection);
                    }
                    this.selectionsDiscreteValue.setSelections(selections);
                },
                getSelectionRangeFromSliderData: function(sliderData) {
                    var range = new Range(sliderData.min, sliderData.max);
                    return new SelectionRange(sliderData.name, sliderData.displayName, range);
                },
                isSliderDataValid: function(sliderData) {
                  return sliderData && sliderData.min < sliderData.max;
                },
                setFromSliderDataArray: function(sliders) {
                    var selections = [];
                    if(sliders && sliders.length) {
                        for(var i = 0; i < sliders.length; i++) {

                            // Hint: slider data min max value might change,
                            // therefore min===max is only invalid if the the slider has those value
                            // on first creation.
                            if(this.isSliderDataValid(sliders[i]) ||
                                this.selectionsRange.hasSelection(sliders[i].name)) {

                                selections.push(this.getSelectionRangeFromSliderData(sliders[i]));
                            }
                        }
                    }
                    this.selectionsRange.setSelections(selections);
                },
                getProductFromProductData: function(productIn){
                    var product = new Product(productIn.code, productIn.name);
                    product.desc = decodeHtml(productIn.description);
                    product.productUrl = productIn.fullUrl;
                    product.configurable = productIn.configurable;

                    if(productIn.images && Array.isArray(productIn.images)) {
                        for(var i = 0; i < productIn.images.length; ++i) {
                            var imageIn = productIn.images[i];
                            if(!imageIn || !imageIn.url) continue;
                            if(!product.imageUrl || imageIn.format === 'thumbnail') {
                                product.imageUrl = imageIn.url;
                                product.imageAltText = imageIn.altText;
                            }
                        }
                    }

                    return product;
                },
                setFromProductDataList: function(products) {
                    this.products.products = [];
                    for(var i = 0; i < products.length; i++) {
                        this.products.products.push(this.getProductFromProductData(products[i]));
                    }
                },
                selectedFacetValuesMap: function() {
                    var selectionMap = {};
                    for(var i = 0; i < this.selectionsDiscreteValue.length(); i++) {
                        var discreteValue = this.selectionsDiscreteValue.get(i).discreteValue;
                        if(discreteValue.hasSelection()) {
                            selectionMap[this.selectionsDiscreteValue.get(i).id] = discreteValue.facetValue.id;
                        }
                    }
                    return selectionMap;
                },
                sliderValuesMap: function() {
                    var rangeMap = {};
                    for(var i = 0; i < this.selectionsRange.length(); i++) {
                        var selectionRange = this.selectionsRange.get(i);
                        rangeMap[selectionRange.id] = selectionRange.range.val1 + '$' + selectionRange.range.val2;
                    }
                    return rangeMap;
                },
                action_getActuators: function(ignoreSelections) {

                    var _this = this;

                    var searchType = 'PREFERRED';
                    if(this.mode.id === Mode.modeAdv) searchType = 'ALLACTUATORS';
                    else if(this.mode.id === Mode.modeMan) searchType = 'MANUAL';

                    var selectedFacetValue = ignoreSelections ? {} : this.selectedFacetValuesMap();
                    var sliderValues = ignoreSelections ? {} : this.sliderValuesMap();

                    this.setActuatorSearchRunning(true);

                    this.setFromProductDataList([]);
                    stateChangedOnlyProductData();

                    // Using setTimeout for testing purposes.
                    setTimeout(function() {

                        $.ajax({
                            url: consts.urlSearch,
                            method: 'GET',
                            data: {
                                valve: _this.sourceProductId,
                                type: searchType,
                                values: selectedFacetValue,
                                sliders: sliderValues
                            }
                        }).done(function (data) {

                            _this.setActuatorSearchRunning(false);

                            // if 'maxProductCount' equals one, the variable has not been set for the current product.'
                            if(_this.maxProductCount === -1) {
                                _this.maxProductCount = data.products.length;
                            }
                            _this.setFromFacetDataList(data.facets);
                            _this.setFromSliderDataArray(data.sliderInfos);
                            _this.setFromProductDataList(data.products);

                            stateChanged();

                        }).fail(function (error) {
                            _this.setActuatorSearchRunning(false);
                            console.log(error);
                        });
                    }, 0);
                },

                action_getComboProduct: function() {

                    var _this = this;
                    this.setComboSearchRunning(true);
                    stateChangedNoData();

                    // Using setTimeout for testing purposes.
                    setTimeout(function() {

                        $.ajax({
                            url: consts.urlCombo,
                            method: 'GET',
                            data: {
                                valve: _this.sourceProductId,
                                actuator: _this.selectedActuatorId,
                            }
                        }).done(function(data) {

                            _this.setComboSearchRunning(false);

                            if(!data.comboMountingPipeOptionsMap || !data.products) {
                                stateChangedNoData();
                                ui.elems.$errorDialog.trigger('show-info-messages', [[selectErrorMsg], true]);
                                return;
                            }

                            state.dataModelCombo.setOptionsFromOptionData(data.comboMountingPipeOptionsMap);
                            state.dataModelCombo.setRawComboData(data);

                            if (state.dataModelCombo.comboData.hasPipeConnectorOption ||
                                state.dataModelCombo.comboData.hasMountingOption) {

                                ui.elems.$popupComboWrapper.trigger('popup-open', [function () {
                                    stateChangedNoData();
                                    uiCombo.draw(state);
                                }]);
                            } else {
                                state.dataModelCombo.navigateToCombo();
                            }

                        }).fail(function (error) {
                            _this.setComboSearchRunning(false);
                            console.log(error);
                        });
                    }, 0);
                },

                setActuatorSearchRunning: function(actuatorSearchRunning) {

                    this.actuatorSearchRunningLight = actuatorSearchRunning;

                    if(this.actuatorSearchRunningTimeoutFn) {
                        clearTimeout(this.actuatorSearchRunningTimeoutFn);
                        this.actuatorSearchRunningTimeoutFn = null;
                    }

                    if(this.actuatorSearchRunning === actuatorSearchRunning) return;
                    if(!actuatorSearchRunning) {
                        this.actuatorSearchRunning = actuatorSearchRunning;
                    }
                    else {
                        var _this = this;
                        _this.actuatorSearchRunningTimeoutFn = setTimeout(function() {
                            _this.actuatorSearchRunning = actuatorSearchRunning;
                            stateChangedNoData();
                        }, 500);
                    }
                },
                setComboSearchRunning: function(comboSearchRunning) {

                    this.comboSearchRunningLight = comboSearchRunning;

                    if(this.comboSearchRunningTimeoutFn) {
                        clearTimeout(this.comboSearchRunningTimeoutFn);
                        this.comboSearchRunningTimeoutFn = null;
                    }
                    if(this.comboSearchRunning === comboSearchRunning) return;
                    if(!comboSearchRunning) {
                        this.comboSearchRunning = comboSearchRunning;
                    }
                    else {
                        var _this = this;
                        _this.comboSearchRunningTimeoutFn = setTimeout(function() {
                            _this.comboSearchRunning = comboSearchRunning;
                            stateChangedNoData();
                        }, 500);
                    }
                }


            },
            displayModel: {
                mobile: false,
                productSelectionsHeight: 0,
                setMobile: function(mobile, callStateChanged) {
                    if(this.mobile === mobile) return;
                    this.mobile = mobile;
                    if(callStateChanged) stateChanged();
                },
                setProductSelectionsHeight: function(height) {
                    this.productSelectionsHeight = height;
                }
            }
        };

        var uiCombo = {
            elems: {
                $sectionMounted: $('.acs-id-combo-option-mounted'),
                $sectionConnector: $('.acs-id-combo-option-connector'),
                $separatorLine: $('.asc-id-combo-separator'),
                $buttonOk: $('.acs-id-combo-ctrls-ok'),

                $buttonMounted: $('.acs-id-combo-mounted-ctrls-mounted'),
                $buttonUnmounted: $('.acs-id-combo-mounted-ctrls-unmounted'),
                $buttonConnectorYes: $('.acs-id-combo-connector-ctrls-no'),
                $buttonConnectorNo: $('.acs-id-combo-connector-ctrls-yes'),

                $buttonsMounted: $('.acs-id-combo-mounted-ctrls button'),
                $buttonsConnector: $('.acs-id-combo-connector-ctrls button'),
                $popupComboWrapper: $('.acs-id-combo'),
                $popupClose: $('.acs-id-combo-action-close')
            },
            initHandlers: function(state) {
                var _this = this;
                this.elems.$buttonsMounted.click(function(){
                    state.dataModelCombo.comboData.mounted = !state.dataModelCombo.comboData.mounted;
                    _this.draw(state);
                });
                this.elems.$buttonsConnector.click(function(){
                    state.dataModelCombo.comboData.connector = !state.dataModelCombo.comboData.connector;
                    _this.draw(state);
                });
                this.elems.$buttonOk.click(function(){
                    _this.elems.$popupComboWrapper.trigger('popup-close');
                    state.dataModelCombo.navigateToCombo();
                });
                this.elems.$popupClose.click(function(){
                    state.dataModel.setComboSearchRunning(false);
                    stateChangedNoData();
                });
            },
            draw: function(state) {

                var showLine = state.dataModelCombo.comboData.hasMountingOption &&
                    state.dataModelCombo.comboData.hasPipeConnectorOption;
                this.elems.$separatorLine.css('display', showLine ? 'block' : 'none');

                this.elems.$sectionMounted.css('display',
                    state.dataModelCombo.comboData.hasMountingOption ? 'block' : 'none');

                this.elems.$sectionConnector.css('display',
                    state.dataModelCombo.comboData.hasPipeConnectorOption ? 'block' : 'none');

                this.elems.$buttonMounted.prop('disabled', state.dataModelCombo.comboData.mounted);
                this.elems.$buttonUnmounted.prop('disabled', !state.dataModelCombo.comboData.mounted);

                this.elems.$buttonConnectorYes.prop('disabled', !state.dataModelCombo.comboData.connector);
                this.elems.$buttonConnectorNo.prop('disabled', state.dataModelCombo.comboData.connector);
            }
        };

        var ui = {

            consts: {
                productsMinHeightMobile: 400,
                productsDisplayCountMobile: 5,
            },
            elems: {
                $topLevelView: $('.acs-mode-selection-wrap, .acs-main-wrap'),
                $mainView: $('.acs-main-wrap'),
                $selectBoxesContainer: $('.acs-id-select-boxes'),
                $selectBoxPrototype: $('.acs-product-selections div.acs-id-select-prototype'),
                $selectBoxes: function(){
                    return $('.acs-id-select-boxes:not(.acs-id-select-prototype) select');
                },
                $selectBox: function(id) {
                    return $('.acs-id-select-boxes select.' + id);
                },
                $selectBoxLabel: function(id) {
                    return $('.acs-id-select-boxes .acs-select-box.' + id + ' label');
                },
                $selectBoxOptions: function(id) {
                    var selector = 'option';
                    return this.$selectBox(id).find(selector);
                },
                $sliderContainer: $('.acs-id-ranges'),
                $sliderPrototype: $('.acs-product-selections div.acs-id-range-prototype'),
                $ranges: $('.acs-id-ranges'),
                $rangeInputs: function(){
                    return this.$ranges.find('.acs-range-input');
                },
                $rangeInputFields: function() {
                    return this.$ranges.find('.acs-range-input-field input');
                },
                $rangeInputFieldMin: function(id) {
                    return this.$ranges.find('.' + id + ' .acs-range-input-min');
                },
                $rangeInputFieldMax: function(id) {
                    return this.$ranges.find('.' + id + ' .acs-range-input-max');
                },
                $rangeSlider: function(id) {
                    return this.$ranges.find('.' + id + ' .range-slider-nouislider');
                },
                $rangeSliders: function() {
                    return this.$ranges.find('.acs-range-input:not(.acs-id-range-prototype) .range-slider-nouislider');
                },
                $productTemplate: $('.acs-product-template'),
                $productWrapCount: $('.acs-products-wrap .acs-products-count'),
                $products: $('.acs-products'),
                $productSelections: $('.acs-product-selections'),
                $productSelectionsWrap: $('.acs-product-selections-wrap'),
                $productsWrap: $('.acs-products-wrap'),
                $actionOpen: $('.acs-action-open'),
                $modeSelectionAction: $('#acs-id-mode-selection'),
                $clearAction: function() {
                    return $('.acs-action-clear');
                },
                $popupComboWrapper: $('.acs-id-combo'),
                $selectActuator: function($product) {
                    return $product.find('.acs-action-select-actuator');
                },
                $modeSelection: $('.acs-mode-selection'),
                $spinner: $('.acs-products-spinner'),
                $errorDialog: $('.info-msgs-container'),
                $allProductSpinnersDesktop: function() {
                    return $('.acs-product-ctrls .acs-product-spinner');
                },
                $allProductSpinnersMobile: function() {
                    return $('.acs-product-bottom-ctrls .acs-product-spinner');
                },
                $modeSelectionInputs: $('.acs-mode-selection input'),
                $modeSelectionInput: function(modeId) {
                    return this.$modeSelectionInputs.filter('input[value="' + modeId + '"]');
                }
            },
            initSelectBoxHandlers: function(dataModel) {
                this.elems.$selectBoxes().change(function() {
                    var $selectedOption = $(this).find('option:selected');
                    if($selectedOption.length != 1) return;
                    var selectionId = $(this).attr('data-acs-id');
                    dataModel.setDiscreteValue(selectionId, $selectedOption.val());
                });
            },
            initSliderHandlers: function(dataModel) {
                var _this = this;

                this.elems.$rangeSliders().each(function() {
                    var $this = $(this);
                    var rangeId = $this.attr('data-acs-id');
                    var step = $this.attr('data-acs-slider-step');
                    var precision = step < 1 ? countDecimals(step) : 0;
                    var $rangeInputMin = _this.elems.$rangeInputFieldMin(rangeId);
                    var $rangeInputMax = _this.elems.$rangeInputFieldMax(rangeId);

                    function getNumbers(values) {
                        var numberValues = [];
                        if(values && values.length) {
                            for(var i = 0; i < values.length; i++) {
                                numberValues.push(Number(values[i]).toFixed(precision));
                            }
                        }
                        return numberValues;
                    }

                    $this.get(0).noUiSlider.on('change', function(values, handleIndex) {
                        var numbers = getNumbers(values);
                        if(handleIndex === 0) numbers[1] = dataModel.range(rangeId).val2;
                        else if(handleIndex === 1) numbers[0] = dataModel.range(rangeId).val1;
                        dataModel.setRangeConstrained(rangeId, numbers[0], numbers[1]);
                    });
                    $this.get(0).noUiSlider.on('update', function(values, handleIndex) {
                        var numbers = getNumbers(values);
                        if(handleIndex === 0) $rangeInputMin.val(numbers[0]);
                        else if(handleIndex === 1) $rangeInputMax.val(numbers[1]);
                    });
                });
                this.elems.$rangeInputFields().each(function() {

                    function onRangeInputFieldChange($srcElem) {
                        var rangeId = $srcElem.attr('data-acs-id');
                        var val1 = _this.elems.$rangeInputFieldMin(rangeId).val();
                        var val2 = _this.elems.$rangeInputFieldMax(rangeId).val();

                        dataModel.setRangeConstrained(rangeId, val1, val2);
                        val1 = dataModel.range(rangeId).val1;
                        val2 = dataModel.range(rangeId).val2;

                        if(_this.elems.$rangeSlider(rangeId).length !== 0) {
                            _this.elems.$rangeSlider(rangeId).get(0).noUiSlider.set([val1, val2], false);
                        }
                        _this.elems.$rangeInputFieldMin(rangeId).val(val1);
                        _this.elems.$rangeInputFieldMax(rangeId).val(val2);
                    }

                    $(this).on('change', function() {
                        onRangeInputFieldChange($(this));
                    });

                    // This condition will only ever be true on all Internet Explorer versions.
                    if(window.document.documentMode) {

                        $(this).on('keyup', function(e) {
                            var key = e.which || e.keyCode || 0;
                            if(key == 13) {
                                onRangeInputFieldChange($(this));
                            }
                        });
                    }
                });
            },

            initHandlers: function(state) {
                var _this = this;
                this.elems.$modeSelectionAction.on('radio-button-click', function(e, groupId, id) {
                    if(groupId !== 'acs-id-mode-selection') return;
                    state.dataModel.setMode(id);
                });

                this.elems.$clearAction().click(function() {
                    state.dataModel.resetSelections();
                });

                this.elems.$errorDialog.on('info-msgs-close', function() {
                    // Nothing currently
                });

                $(window).on('resize', function() {
                    state.displayModel.setMobile(_this.isMobile(), true);
                });
            },
            init: function(state) {

                var $defaultOption = $('select option[value="completeRange"]').first();

                state.dataModel.setDefaultOptionText($defaultOption.text());
                state.dataModel.setDefaultOptionClearText($defaultOption.attr('data-acs-option-clear-text'));
            },
            updateSelectBox: function(selectionDiscreteValue) {

                var $selectBox = this.elems.$selectBox(selectionDiscreteValue.id);
                var discreteValue = selectionDiscreteValue.discreteValue;
                var facetValue = discreteValue.facetValue;


                var $defaultOption = this.elems.$selectBoxPrototype.find('option');
                this.elems.$selectBoxOptions(selectionDiscreteValue.id).remove();

                for(var i = 0; i < discreteValue.allFacetValues.length; i++) {
                    var $option = $defaultOption.clone();
                    var currentFacetValue = discreteValue.allFacetValues[i];
                    var displayName = currentFacetValue.displayName;
                    $option.val(currentFacetValue.displayName);
                    $option.text(currentFacetValue.displayName);

                    var facetValueEnabled = discreteValue.getFacetValue('displayName', displayName, true);
                    if(!facetValueEnabled) $option.prop('disabled', true);
                    if(currentFacetValue.equals(facetValue)) $option.prop('selected', true);

                    var showDisabledSelectOption = false;
                    if(showDisabledSelectOption || facetValueEnabled) {
                        $selectBox.append($option);
                    }
                }
                this.updateSelectBoxState(selectionDiscreteValue);
            },

            updateSelectBoxes: function(dataModel) {
                for(var i = 0; i < dataModel.selectionsDiscreteValue.length(); i++) {
                    this.updateSelectBox(dataModel.selectionsDiscreteValue.get(i));
                }
            },
            updateSelectBoxState: function(selectionDiscreteValue) {
                var $selectBox = this.elems.$selectBox(selectionDiscreteValue.id);
                var discreteValue = selectionDiscreteValue.discreteValue;
                var hasEnabledValue = discreteValue.hasEnabledValue();
                $selectBox.prop('disabled', !hasEnabledValue);

                // Related label
                var $label = this.elems.$selectBoxLabel(selectionDiscreteValue.id);
                hasEnabledValue ? $label.removeClass('disabled') : $label.addClass('disabled');
            },
            updateSelectBoxesState: function(dataModel) {

                dataModel.actuatorSearchRunningLight
                    ? this.elems.$selectBoxes().addClass('disabled-light')
                    : this.elems.$selectBoxes().removeClass('disabled-light');

                if(dataModel.actuatorSearchRunning){
                    this.elems.$selectBoxes().prop('disabled', true);
                }
                else {
                    for(var i = 0; i < dataModel.selectionsDiscreteValue.length(); i++) {
                        this.updateSelectBoxState(dataModel.selectionsDiscreteValue.get(i));
                    }
                }
            },
            $createSelectBox: function(selectionDiscreteValue) {

                var $selectBoxDiv = this.elems.$selectBoxPrototype.clone(true, true);
                $selectBoxDiv.removeClass('acs-id-select-prototype');
                $selectBoxDiv.css('display', 'flex');

                var $selectBoxLabel = $selectBoxDiv.find('label');
                var $selectBox = $selectBoxDiv.find('select');

                $selectBoxDiv.removeClass('acs-id-select-prototype');
                $selectBoxDiv.addClass(selectionDiscreteValue.id);
                $selectBoxDiv.css('display', 'flex');

                $selectBoxLabel.attr('for', selectionDiscreteValue.id);
                $selectBoxLabel.text(selectionDiscreteValue.name);

                $selectBox.attr('id', selectionDiscreteValue.id);
                $selectBox.attr('name', selectionDiscreteValue.id);
                $selectBox.removeClass('acs-id-select-prototype');
                $selectBox.addClass(selectionDiscreteValue.id);
                $selectBox.attr('data-acs-id', selectionDiscreteValue.id);

                return $selectBoxDiv;
            },

            setSelectBoxes: function(selectionsDiscreteValue) {
                this.elems.$selectBoxesContainer.empty();

                for(var i = 0; i < selectionsDiscreteValue.length(); i++) {
                    var $selectBox = this.$createSelectBox(selectionsDiscreteValue.get(i));
                    this.elems.$selectBoxesContainer.append($selectBox);
                }
            },
            $createRangeSlider: function(selectionRange) {

                function calcStep(min, max, displayWidth) {

                    function roundToNearestPowerOfThen(val) {
                        return Math.pow(10, Math.round(Math.log10(val)));
                    }

                    // Approximate step value
                    var step = Math.abs(max - min) / displayWidth;

                    // Approximate step value ... 0.001, 0.1, 1, 10, ...
                    step = roundToNearestPowerOfThen(step);

                    // The step value should not be larger than the given precision of min or max.
                    var minDecimals = Math.max(countDecimals(min), countDecimals(max));
                    if(countDecimals(step) < minDecimals) {
                        while(countDecimals(step) < minDecimals) {
                            step /= 10;
                        }
                    }

                    if(step > 1) {
                        step = 1;
                    }

                    return step;
                }

                var range = selectionRange.range;
                var $sliderDiv = this.elems.$sliderPrototype.clone(true, true);
                $sliderDiv.removeClass('acs-id-range-prototype');
                $sliderDiv.addClass(selectionRange.id);
                $sliderDiv.attr('data-acs-id', selectionRange.id);

                var $sliderLabel = $sliderDiv.find('>label');
                var $inputMinLabel = $sliderDiv.find('label[for$="min"]');
                var $inputMaxLabel = $sliderDiv.find('label[for$="max"]');
                var $inputMin = $sliderDiv.find('.acs-range-input-min');
                var $inputMax = $sliderDiv.find('.acs-range-input-max');

                $sliderLabel.text(selectionRange.name);
                $inputMinLabel.attr('for', selectionRange.id + '-min');
                $inputMaxLabel.attr('for', selectionRange.id + '-max');

                $inputMin.attr('id', selectionRange.id + '-min');
                $inputMin.attr('data-acs-id', selectionRange.id);

                $inputMax.attr('id', selectionRange.id + '-max');
                $inputMax.attr('data-acs-id', selectionRange.id);


                var $slider = $sliderDiv.find('.range-slider-nouislider');
                $slider.attr('data-acs-id', selectionRange.id);

                var step = calcStep(range.min, range.max, 500);

                this.createNoUiSlider($slider, range, step);

                $slider.attr('data-acs-slider-step', step);

                return $sliderDiv;
            },

            createNoUiSlider: function($slider, range, step) {

                var precision = step < 1 ? countDecimals(step) : 0;

                var sliderConfig = {
                    start: [range.val1, range.val2],
                    connect: true,
                    step: step,
                    behaviour: 'tap',
                    range: {
                        'min': range.min,
                        'max': range.max
                    },
                    pips: {
                        mode: range.scaleMarks && range.scaleMarks.length > 0 ? 'values' : 'count',
                        values: range.scaleMarks && range.scaleMarks.length > 0 ? range.scaleMarks : 2,
                        density: range.scaleMarks && range.scaleMarks.length > 0 ? 12 : 100,
                        format: {
                            to: function(value) {
                                return value.toFixed(precision) + '';
                            },
                            from: function(value) {
                                return Number(value).toFixed(precision);
                            }
                        }
                    }
                    // pips: {
                    //     mode: range.scaleMarks && range.scaleMarks.length > 0 ? 'values' : 'count',
                    //     values: range.scaleMarks && range.scaleMarks.length > 0 ? range.scaleMarks : 2,
                    //     density: 12
                    // }
                };

                window.noUiSlider.create($slider.get(0), sliderConfig);
            },

            setRangeSliders: function(selectionsRange) {
                this.elems.$sliderContainer.empty();
                for(var i = 0; i < selectionsRange.length(); i++) {
                    var $slider = this.$createRangeSlider(selectionsRange.get(i));
                    this.elems.$sliderContainer.append($slider);
                }
            },

            updateRangeInputs: function(dataModel) {
                for(var i = 0; i < dataModel.selectionsRange.length(); i++) {

                    var selectionRange = dataModel.selectionsRange.get(i);
                    var id = selectionRange.id;
                    var range = selectionRange.range;

                    if(this.elems.$rangeSlider(id).length !== 0) {
                        this.elems.$rangeSlider(id).get(0).noUiSlider.set([range.getVal1(), range.getVal2()], false);
                    }

                    this.elems.$rangeInputFieldMin(id).val(range.getVal1());
                    this.elems.$rangeInputFieldMax(id).val(range.getVal2());
                }
            },
            updateRangeInputsState: function(dataModel) {

                dataModel.actuatorSearchRunningLight
                    ? this.elems.$rangeInputs().addClass('disabled-light')
                    : this.elems.$rangeInputs().removeClass('disabled-light');

                dataModel.actuatorSearchRunning
                    ? this.elems.$rangeInputs().addClass('disabled')
                    : this.elems.$rangeInputs().removeClass('disabled');
            },
            updateProducts: function(dataModel) {

                var productList = dataModel.products.products;

                this.elems.$products.css('height', '');

                this.elems.$productWrapCount.text(
                    dataModel.products.count()
                    + '/'
                    + (dataModel.maxProductCount === -1 ? 0 : dataModel.maxProductCount));

                $('.acs-product').remove();

                var selectButtonText = dataModel.mode.isModeManual()
                    ? this.elems.$selectActuator(this.elems.$productTemplate).attr('data-acs-text-select-manual')
                    : this.elems.$selectActuator(this.elems.$productTemplate).attr('data-acs-text-select');

                for(var i = 0; i < productList.length; i++) {
                    var product = productList[i];
                    var $product = this.elems.$productTemplate.clone();
                    $product.removeClass('acs-product-template');
                    $product.addClass('acs-product');

                    $product.attr('data-product-id', product.id);
                    $product.find('.acs-product-link').attr('href', product.productUrl);
                    $product.find('.acs-product-link').text(product.name);

                    $product.find('.acs-product-image-wrap').attr('href', product.productUrl);

                    if(product.imageUrl) {
                        $product.find('.acs-product-image-wrap img').attr('src', product.imageUrl);
                    }
                    if(product.imageAltText) {
                        $product.find('.acs-product-image-wrap img').attr('alt', product.imageAltText);
                    }

                    if(!product.configurable) {
                        $product.find('.acs-product-info .acs-product-configurable').text('');
                    }
                    $product.find('.acs-product-info .acs-product-desc').text(product.desc);

                    this.elems.$selectActuator($product).find('span').text(selectButtonText);

                    this.elems.$selectActuator($product).click(function(){

                        dataModel.selectedActuatorId = $(this).closest('.acs-product').attr('data-product-id');
                        dataModel.action_getComboProduct();
                    });

                    this.elems.$products.append($product);
                }
            },
            updateProductsState: function(dataModel) {

                dataModel.actuatorSearchRunningLight
                    ? this.elems.$products.addClass('disabled-light')
                    : this.elems.$products.removeClass('disabled-light');

                dataModel.actuatorSearchRunning
                    ? this.elems.$products.addClass('disabled')
                    : this.elems.$products.removeClass('disabled');
            },
            applyDataModel: function(dataModel, displayModel) {

                if(dataModel.selectionsDiscreteValue.isDirty()) {
                    this.setSelectBoxes(dataModel.selectionsDiscreteValue);
                    this.initSelectBoxHandlers(dataModel);
                    dataModel.selectionsDiscreteValue.setDirtyFlag(false);
                }
                this.updateSelectBoxes(dataModel);

                if(dataModel.selectionsRange.isDirty()) {
                    this.setRangeSliders(dataModel.selectionsRange);
                    this.initSliderHandlers(dataModel);
                    dataModel.selectionsRange.setDirtyFlag(false);
                }
                this.updateRangeInputs(dataModel);

                displayModel.setProductSelectionsHeight(this.elems.$productSelectionsWrap.height());

                this.updateProducts(dataModel);
            },

            setInitialState: function(state) {
                state.displayModel.setMobile(this.isMobile(), false);
            },
            isMobile: function() {

                var mobile = this.elems.$mainView.css('--is-mobile');
                return mobile === 'true';

                // var ml = this.elems.$productsWrap.css('margin-left');
                // return ml === '0' || ml === '0px';
            },
            adjustProductsHeight: function(displayModel) {

                this.elems.$products.css('height', 'auto');
                if(!displayModel.mobile){
                    this.elems.$products.css('max-height', '');
                    // var hs = this.elems.$productSelectionsWrap.height();
                    var hs = displayModel.productSelectionsHeight;
                    var hp = this.elems.$products.height();
                    if(hs > 0 && hp > hs) {
                        this.elems.$products.height(hs);
                    }
                }
            },

            setClearButtonState: function (dataModel) {
                const $clearAction = this.elems.$clearAction();

                $clearAction.toggleClass('disabled-light', dataModel.actuatorSearchRunningLight);

                const shouldEnableClearAction =
                    dataModel.canResetSelections() &&
                    (!dataModel.actuatorSearchRunning || dataModel.products.products.length === 0);

                $clearAction.prop('disabled', !shouldEnableClearAction);
            },


            setModeSelectionState: function(dataModel) {

                this.elems.$modeSelectionInputs.prop('checked', false);
                this.elems.$modeSelectionInput(dataModel.mode.id).prop('checked', true);

                dataModel.actuatorSearchRunningLight
                    ? this.elems.$modeSelection.addClass('disabled-light')
                    : this.elems.$modeSelection.removeClass('disabled-light');

                dataModel.actuatorSearchRunning
                    ? this.elems.$modeSelection.addClass('disabled')
                    : this.elems.$modeSelection.removeClass('disabled');
            },
            setSpinnerAndProductsState: function(dataModel) {
                (dataModel.actuatorSearchRunningLight || dataModel.comboSearchRunningLight)
                    ? this.elems.$products.addClass('disabled-light')
                    : this.elems.$products.removeClass('disabled-light');

                if(dataModel.actuatorSearchRunning/* || dataModel.comboSearchRunning*/) {
                    this.elems.$products.hide();
                    this.elems.$spinner.removeClass('disabled');
                    if(this.isMobile()){
                        this.elems.$productsWrap.css('height', '150px');
                    }
                    var _this = this;
                    setTimeout(function() {
                        _this.elems.$spinner.css('opacity', 1.0);
                    }, 0);
                }
                else {
                    this.elems.$products.fadeIn(100);
                    this.elems.$spinner.addClass('disabled');
                    this.elems.$spinner.css('opacity', 0.0);
                    this.elems.$productsWrap.css('height', 'auto');
                }
            },
            setDisplayOfQueryingText: function(dataModel) {
                if(dataModel.actuatorSearchRunning) {
                    $('.acs-products-count').hide();
                    $('.acs-products-result-text').hide();
                    $('.acs-products-query-text').show();
                }
                else {
                    $('.acs-products-query-text').hide();
                    $('.acs-products-count').show();
                    $('.acs-products-result-text').show();
                    this.elems.$productsWrap.css('height', 'auto');
                }
            },
            setMainViewState: function(dataModel) {

                dataModel.comboSearchRunningLight
                    ? this.elems.$topLevelView.addClass('disabled-light')
                    : this.elems.$topLevelView.removeClass('disabled-light');

                dataModel.comboSearchRunning
                    ? this.elems.$topLevelView.addClass('disabled')
                    : this.elems.$topLevelView.removeClass('disabled');
            },
            setPanelsDisplayByMode: function(dataModel) {
                dataModel.mode.isModeManual()
                    ? this.elems.$mainView.addClass('manual')
                    : this.elems.$mainView.removeClass('manual');
            },
            setProductSpinner: function(dataModel) {

                if(!dataModel.comboSearchRunningLight) {
                    this.elems.$allProductSpinnersDesktop().removeClass('enabled');
                    this.elems.$allProductSpinnersMobile().removeClass('enabled');
                }
                else {
                    var classNameSpinner = '.acs-product-spinner-' + dataModel.sourceProductId;
                    this.isMobile()
                        ? this.elems.$allProductSpinnersMobile().filter(classNameSpinner).addClass('enabled')
                        : this.elems.$allProductSpinnersDesktop().filter(classNameSpinner).addClass('enabled');
                }
            },

            draw: function(state) {
                this.applyDataModel(state.dataModel, state.displayModel);
                this.setPanelsDisplayByMode(state.dataModel);
                this.adjustProductsHeight(state.displayModel);
                this.drawNoDataChange(state);
            },
            drawOnlyProductDataChange: function(state) {
                this.updateProducts(state.dataModel);
                this.adjustProductsHeight(state.displayModel.mobile);
                this.drawNoDataChange(state);
            },
            drawNoDataChange: function(state) {
                this.setMainViewState(state.dataModel);
                this.updateSelectBoxesState(state.dataModel);
                this.updateRangeInputsState(state.dataModel);
                this.updateProductsState(state.dataModel);
                this.setClearButtonState(state.dataModel);
                this.setModeSelectionState(state.dataModel);
                this.setSpinnerAndProductsState(state.dataModel);
                this.setDisplayOfQueryingText(state.dataModel);
                this.setProductSpinner(state.dataModel);
            }
        };

        return {
            initAcsView: function() {
                ui.setInitialState(state);
                ui.init(state);
                ui.initHandlers(state);
                uiCombo.initHandlers(state);
            },
            setSourceProductId: function(sourceProjectId, sourceProductName) {
                state.dataModel.setSourceProductId(sourceProjectId, sourceProductName);
                state.dataModelCombo.reset();
            }
        };
    }
})();
